import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Card } from "react-bootstrap"
import Parser from "html-react-parser"

import ButtonDarkAndroid from "../button/dark-android"
import ButtonDarkiOS from "../button/dark-ios"
import ButtonGetStarted from "../button/get-started"

const CardApp = ({
  title,
  description,
  image,
  alt,
  path,
  ios,
  android,
  website,
  affiliate_app,
  affiliate_web,
  platform,
}) => (
  <Card className="card-app">
    <Card.Header>
      <Link to={path}>
        <Img className="card-app-image" fluid={image} alt={alt} />
      </Link>
    </Card.Header>
    <Card.Body>
      <Card.Title>
        <Link to={path}>{Parser(title)}</Link>
      </Card.Title>
      <Card.Text>{Parser(description)}</Card.Text>
    </Card.Body>
    <Card.Footer>
      {platform.indexOf("iOS") !== -1 || platform.indexOf("Android") !== -1 ? (
        <div className="link-app">
          <ButtonDarkiOS
            href={ios}
            affiliate={affiliate_app}
            coming={website}
          />
          <ButtonDarkAndroid
            href={android}
            affiliate={affiliate_app}
            coming={website}
          />
        </div>
      ) : (
        <div className="link-website">
          <ButtonGetStarted href={website} affiliate={affiliate_web} />
        </div>
      )}
      <Link className="learn-more" to={path}>
        Learn More &#8594;
      </Link>
    </Card.Footer>
  </Card>
)

CardApp.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  ios: PropTypes.string,
  android: PropTypes.string,
  website: PropTypes.string,
  affiliate_app: PropTypes.string,
  affiliate_web: PropTypes.string,
  platform: PropTypes.array,
}

export default CardApp
