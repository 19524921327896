import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import AffiliateLink from "./affiliate-link"

const ButtonDarkiOS = ({ href, affiliate, coming }) => {
  const { iosDownload, iosComingSoon } = useStaticQuery(graphql`
    query {
      iosDownload: file(relativePath: { eq: "Dark_Download_Appstore@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 175) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iosComingSoon: file(
        relativePath: { eq: "Dark_Preorder_Appstore@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 175) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return href ? (
    <AffiliateLink
      href={href}
      affiliate={affiliate}
      className="link-app-button ios"
    >
      <Img
        fluid={iosDownload.childImageSharp.fluid}
        alt="Download on the App Store"
      />
    </AffiliateLink>
  ) : coming ? (
    <AffiliateLink
      href={coming}
      affiliate={affiliate}
      className="link-app-button link-app-coming-soon ios"
    >
      <Img
        fluid={iosComingSoon.childImageSharp.fluid}
        alt="Coming Soon on the App Store"
      />
    </AffiliateLink>
  ) : (
    <span className="link-app-button link-app-coming-soon ios">
      <Img
        fluid={iosComingSoon.childImageSharp.fluid}
        alt="Coming Soon on the App Store"
      />
    </span>
  )
}

ButtonDarkiOS.propTypes = {
  href: PropTypes.string,
  affiliate: PropTypes.string,
  coming: PropTypes.string,
}

export default ButtonDarkiOS
