import React from "react"
import PropTypes from "prop-types"
import { isSafari, isMobileSafari } from "react-device-detect"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const AffiliateLink = ({ children, href, affiliate, ...props }) => {
  const handleAffiliateButton = (e) => {
    e.preventDefault()
    window.open(affiliate ? affiliate : href, "_blank")
  }

  const handleAffiliateLinkSwap = (e) => {
    if (affiliate) e.target.href = affiliate
  }

  const handleHrefLinkSwap = (e) => {
    if (affiliate) e.target.href = href
  }

  return isSafari || isMobileSafari ? (
    isMobileSafari ? (
      <OutboundLink
        {...props}
        href={affiliate}
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        {children}
      </OutboundLink>
    ) : (
      <OutboundLink {...props} href={href} onClick={handleAffiliateButton}>
        {children}
      </OutboundLink>
    )
  ) : (
    <OutboundLink
      {...props}
      href={href}
      rel="nofollow noopener noreferrer"
      target="_blank"
      onClick={handleAffiliateLinkSwap}
      onMouseUp={handleAffiliateLinkSwap}
      onFocus={handleAffiliateLinkSwap}
      onBlur={handleHrefLinkSwap}
      onMouseOver={handleHrefLinkSwap}
    >
      {children}
    </OutboundLink>
  )
}

AffiliateLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  affiliate: PropTypes.string,
}

export default AffiliateLink
