import React from "react"
import PropTypes from "prop-types"

import AffiliateLink from "./affiliate-link"

const ButtonGetStarted = ({ href, affiliate }) => (
  <AffiliateLink
    className="button-get-started"
    href={href}
    affiliate={affiliate}
  >
    Get Started
  </AffiliateLink>
)

ButtonGetStarted.propTypes = {
  href: PropTypes.string,
  affiliate: PropTypes.string,
}

export default ButtonGetStarted
